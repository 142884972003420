// quick access to everything related to authorisation

import Cookies from 'js-cookie'

const accessTokenKey = 'tieno-token-key'

export function getToken() {
  return Cookies.get(accessTokenKey)
}

export function setToken(token) {
  return Cookies.set(accessTokenKey, token)
}

export function removeToken() {
  return Cookies.remove(accessTokenKey)
}
