import * as potentialEntitiesApiClient from '@/api/endpoints/entity/potential-entities'

export default {
  namespaced: true,
  state: {
    entities: [],
  },
  mutations: {
    SET_ENTITIES(state, entities) {
      state.entities = entities
    },
  },
  actions: {
    fetchEntities: ({ commit }) => {
      console.log('fetchEntities called')
      potentialEntitiesApiClient.fetchList().then(function (response) {
        console.log('potentialEntitiesApiClient returned')
        console.log(response)
        console.log('right after')
        if (response.status === 'success') {
          console.log('Set entities called')
          commit('SET_ENTITIES', response.data)
        }
      })
    },
  },
  getters: {},
}
