// Main Menu service
// Here we would need to adjust the main menu depending on the user type

// down and dirty,

// //////////////////////////////////////////////////////////////////////
// Consumer
// //////////////////////////////////////////////////////////////////////

export const consumerMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'Home',
    key: '__dashboard',
    url: '/consumer/dashboard',
    icon: 'fe fe-home',
  },
  {
    title: 'Activity',
    key: 'ujv7wp',
    url: '/consumer/activity',
    icon: 'fe fe-battery-charging',
  },
  {
    title: 'Transactions',
    key: '48rd1a',
    url: '/consumer/transactions',
    icon: 'fe fe-dollar-sign',
  },
  {
    category: true,
    title: 'Forecasting',
    key: 'xwltiu',
  },
  {
    title: 'Forecasting',
    key: 'b0isb',
    url: '/consumer/forecasting',
    icon: 'fe fe-bell',
  },
  {
    title: 'Create New Installment Plan',
    key: 'qy8bdi',
    url: '/consumer/forecasting/payment-plan/create',
    icon: 'fe fe-alert-octagon',
  },
  {
    title: 'Analysis',
    key: 'mpiiol',
    url: '/consumer/analysis',
    icon: 'fe fe-activity',
  },
  {
    category: true,
    title: 'Social',
    key: 'consumer-social',
  },
  {
    title: 'Friends',
    key: 'consumer-friends',
    url: '/consumer/friends',
    icon: 'fa fa-group',
  },
  {
    title: 'Account',
    category: true,
    key: 'consumer-account',
    icon: 'fe fe-user',
    // roles: ['admin'], // set user roles with access to this route
    // count: 4,
    children: [
      {
        title: 'Payment Settings',
        key: 'consumer-account-payment-settings',
        url: '/consumer/account/payment-settings/',
        icon: 'fe fe-settings',
      },
      {
        title: 'Personal Preferences',
        key: 'consumer-account-personal-preferences',
        url: '/consumer/account/personal-preferences/',
        icon: 'fe fe-save',
      },
      {
        title: 'KYC',
        key: 'consumer-account-kyc',
        url: '/consumer/account/kyc/',
        icon: 'fe fe-paperclip',
      },
      {
        title: 'Bank Accounts',
        key: 'consumer-account-bank-accounts',
        url: '/consumer/account/bank-accounts/',
        icon: 'fe fe-shield',
      },
      {
        title: 'Credit Cards',
        key: 'consumer-account-credit-cards',
        url: '/consumer/account/credit-cards/',
        icon: 'fe fe-credit-card',
      },
    ],
  },

  // VB:REPLACE-END:MENU-CONFIG
]

// //////////////////////////////////////////////////////////////////////
// Business
// //////////////////////////////////////////////////////////////////////

export const businessMenuData = [

]

// //////////////////////////////////////////////////////////////////////
// Admin
// //////////////////////////////////////////////////////////////////////

export const adminMenuData = [

]


export const doSomething = () => {
    return consumerMenuData
}

/**
 * Sends back the right menu depending on the url, should really be based on state
 * TODO: This is just a workaround, just to show the demo/prototype for now
 * @returns {*}
 */
export function getMenuData() {
  const currentUrl = window.location.pathname
  console.log('Getting Menu Data for ' . currenUrl)
  if (currentUrl.includes('consumer')) {
    return consumerMenuData
  } else if (currentUrl.includes('admin')) {
    return adminMenuData
  } else if (currentUrl.includes('business')) {
    return merchantMenuData
  } else {
    return consumerMenuData
  }
}
