import * as currencyApiClient from '@/api/endpoints/common/currencies'
import * as countriesApiClient from '@/api/endpoints/common/countries'

export default {
  namespaced: true,
  state: {
    currencies: [],
    countries: [],
  },
  mutations: {
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries
    },
  },
  actions: {
    fetchCurrencies: ({ commit }) => {
      console.log('In Action for fetchCurrencies')
      currencyApiClient.fetchList().then(function (response) {
        console.log('Retrieved currencies')
        console.log('Response Status = ' + response.status)
        if (response.status === 'success') {
          commit('SET_CURRENCIES', response.data)
        }
      })
    },
    fetchCountries: ({ commit }) => {
      console.log('In Action for fetchCountries')
      countriesApiClient.fetchList().then(function (response) {
        if (response.status === 'success') {
          commit('SET_COUNTRIES', response.data)
        }
      })
    },
  },
  getters: {
    allCurrencyCodes: (state) => {
      // console.log('common/allCurrencyCodes has been called')
      return state.currencies.map(a => a.currency_code)
      // return state.todos.filter(todo => todo.done)
    },
    getDefaultCurrencyCode: (state) => {
      return 'EGP'
    },
  },
}
