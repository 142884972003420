/**
 * Axios Request script which helps organise all types of requests which are made to the server side
 * Ensure we catch and manage errors, etc.
 * Utilised alot of code found in LaraVue here
 */

import axios from 'axios'
import { getToken } from '@/store/utils/auth'
import { notification } from 'ant-design-vue'
import { isInDebugMode } from '@/utils/debugging'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // use the url from the .env variable
  // headers: { Accept: 'application/json' },
  timeout: 3000, // keeping this to ensure know when there is an issue with a timeout
})

// Request Interceptor
apiClient.interceptors.request.use(
  config => {
    // const token = this.$store.state.user.authorisation.accessToken
    // const token = getToken()
    // const token = store.get('accessToken')
    const token = getToken()
    console.log('API request being made with access token - ' + token)
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
      config.headers.AccessToken = token
      config.headers.Accept = 'application/json'
    }
    return config
  },
  error => {
    console.log(error) // for debug
    // Update based on if is in dev mode
    if (isInDebugMode()) { // this is in development mode
      notification.error({
        message: 'Request Fails on Calling',
        description: error,
        duration: 10000,
      })
    }
    // reject the request
    Promise.reject(error)
  },
)

// Response pre-processing
apiClient.interceptors.response.use(

  response => {
    if (response.data.auth) {
      // const accessToken = response.data.auth.access_token;
      // setToken(response.headers.authorization)
      // store.set(response.data.auth.access_token)

      // response.data.token = response.headers.authorization
    }

    if (isInDebugMode()) {
      const theData = response.data
      const jsonPrintableData = JSON.stringify(theData)
      console.log('Successful response' + jsonPrintableData)
      notification.success({
        message: 'Request successful',
        description: jsonPrintableData,
      })
    }

    return response.data
  },
  error => {
    // let message = error.message
    // if (error.response.data && error.response.data.errors) {
    //   message = error.response.data.errors
    // } else if (error.response.data && error.response.data.error) {
    //   message = error.response.data.error
    // }

    console.log('Error Response - Message: ' + error.message)
    console.log('Error Response - Status Code ' + error.response.status)
    console.log('Error Response - Errors ' + error.response.errors)
    console.log('Error Response - Headers ' + error.response.headers)
    console.log('Error Response - Status Code ' + error.response.status)
    console.log('Error response - Data' + error.response.data)
    console.log('Error response - Data (status): ' + error.response.data.status)
    console.log('Error response - Data (code): ' + error.response.data.code)
    console.log('Error response - Data (message): ' + error.response.data.message)
    console.log('Error response - Errors: ' + error.response.data.errors)

    const errors = error.response.data.errors
    if (errors) {
      var i
      for (i = 0; i < errors.length; i++) {
        const thisError = errors[i]
        console.log('Error ' + i + ' | ' + thisError.type + ' | ' + thisError.code + ' | ' + thisError.parameter + ' | ' + thisError.message)
      }
    }

    if (isInDebugMode()) { // this is in development mode
      const theData = error.response.data
      const jsonPrintableData = JSON.stringify(theData)
      console.log('JSON Response - ' + jsonPrintableData)
      notification.error({
        message: 'Request Failed',
        description: jsonPrintableData,
        duration: 2000,
      })
    }

    // Sentry.captureException(jsonPrintableData)
    return Promise.reject(error)
  },
)

export default apiClient

/**
 *
 * All data structure coming back will be in this format
 * "status": "fail", // or success or error
 * "code": 422,
 * "message": "Data validation failed",
 * data: {
 *    parameter_name: parameter_value
 * }
 * "errors": [
 * {
 *      "type": "validation_error",
 *      "code": "0000",
 *      "message": "The client id field is required.",
 *      "parameter": "client_id"
 *     },
 * {
 *      "type": "validation_error",
 *      "code": "0000",
 *      "message": "The client secret field is required.",
 *      "parameter": "client_secret"
 *     }
 * ]
 */
