import * as myMoneyAccount from '@/api/endpoints/consumer/money-accounts'

export default {
  namespaced: true,
  state: {
    myMoneyAccountSummary: [],
  },
  mutations: {
    SET_MY_MONEY_ACCOUNT_SUMMARY(state, myMoneyAccountSummary) {
      state.myMoneyAccountSummary = myMoneyAccountSummary
    },
  },
  actions: {
    // fetches my account summary
    fetchMyAccountSummary: ({ commit }) => {
      console.log('fetchMyAccountSummary called')
      myMoneyAccount.fetchMyAccountSummary().then(function (response) {
        console.log('fetchMyAccountSummary returned')
        console.log(response)
        if (response.status === 'success') {
          commit('SET_MY_MONEY_ACCOUNT_SUMMARY', response.data)
        }
      })
    },
    // this stores my new pocket
    storeMyNewPocket: ({ commit }, data) => {
      console.log('storeMyNewPocket called')
      // interesting reference - https://stackoverflow.com/questions/40165766/returning-promises-from-vuex-actions
      return new Promise((resolve, reject) => {
        myMoneyAccount.storePocket(data).then(response => {
          if (response.status === 'success') {
            commit('SET_MY_MONEY_ACCOUNT_SUMMARY', response.data)
            resolve(response)
          }
          reject(response.errors)
        }, error => {
          reject(error, error.errors)
        })
      })
    },
  },
  getters: {},
}
