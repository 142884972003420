// OAuth configuration - using Laravel Passport on backend at the moment

import apiClient from '@/api/utils/axios'
// import store from 'store'
import { setToken, removeToken } from '@/store/utils/auth'
// import { notification } from 'ant-design-vue'

// get the authorisation from the ENV file
const oauthConfig = {
  apiURL: process.env.VUE_APP_API_BASE_URL,
  clientID: process.env.VUE_APP_API_CLIENT_ID,
  clientSecret: process.env.VUE_APP_API_CLIENT_SECRET,
}

export async function login(username, password) {
  return apiClient
    .post(oauthConfig.apiURL + '/auth/loginWithUsernamePassword', {
      client_id: oauthConfig.clientID,
      client_secret: oauthConfig.clientSecret,
      username: username,
      password: password,
    })
    .then(response => {
      if (response) {
        const accessToken = response.data.auth.access_token
        if (accessToken) {
          console.log('Storing access token in login - ' + accessToken)
          setToken(accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(error => {
      console.log('Error response ' + error.response)
    })
}

export async function register(email, password, name) {
  return apiClient
    .post(oauthConfig.apiURL + '/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const accessToken = response.data.auth.access_token
        if (accessToken) {
          console.log('Storing access token in register - ' + accessToken)
          setToken(accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get(oauthConfig.apiURL + '/auth/account')
    .then(response => {
      if (response) {
        // this was causing an issue - dont want to update the token with the currentAccount
        // as this would issue a new one and we don't want that yet, user has to login for that, or refresh the token
        // const accessToken = response.data.auth.access_token
        // if (accessToken) {
        //   console.log('Storing access token in current account - ' + accessToken)
        //   // setToken('accessToken', accessToken)
        //   setToken(accessToken)
        // }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      console.log('Removing access token')
      // removeToken('accessToken')
      removeToken() // from the cookie
      // store.commit('clearAll')
      // store.state.logout()
      // store.dispatch('logout')
      return true
    })
    .catch(err => console.log(err))
}
