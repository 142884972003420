
export default {
  methods: {
    numberWithCommas(x) {
      // found in Shikaty, but also from here
      // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
      if (x) {
        // if we want two decimal places
        var number = parseFloat(x)
        number = number.toFixed(2)
        // adds the comma
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return 0
      }
    },
    numberWithCommasAndCurrencyCode(amount, currency) {
      if(amount && currency) {
        return this.numberWithCommas(amount) + " " + currency
      } else if (amount) {
        return this.numberWithCommas(amount)
      } else if (currency) {
        return 0 + " " + currency
      }
    },
  },
}
