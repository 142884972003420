<template>
  <div :class="$style.topbar">
    <div class="mr-auto">You are logged in as {{ user.name }}</div>
<!--    <div class="mr-4">-->
<!--      <vb-fav-pages />-->
<!--    </div>-->
<!--    <div class="mr-auto mr-md-1">-->
<!--      <vb-search />-->
<!--    </div>-->
<!--    <div class="mr-4 d-none d-md-block">-->
<!--      <vb-issues-history />-->
<!--    </div>-->
<!--    <div class="mr-auto d-xl-block d-none">-->
<!--      <vb-project-management />-->
<!--    </div>-->
<!--    <div class="mr-3 d-none d-sm-block ml-auto">-->
<!--      <vb-cart />-->
<!--    </div>-->
    <div class="mr-3 d-none d-sm-block">
      <vb-actions />
    </div>
    <div class="mr-3 d-none d-sm-block">
      <app-language-switcher />
    </div>
    <div>
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
// import VbIssuesHistory from './IssuesHistory'
// import VbProjectManagement from './ProjectManagement'
// import VbSearch from './Search'
import AppLanguageSwitcher from './LanguageSwitcher'
import VbActions from './Actions'
import VbUserMenu from './UserMenu'
// import VbFavPages from './FavPages'
// import VbCart from './Cart'
import { useStore } from 'vuex'
import {computed} from "vue";
export default {
  components: {
    // VbIssuesHistory,
    // VbProjectManagement,
    // VbSearch,
    AppLanguageSwitcher,
    VbActions,
    VbUserMenu,
    // VbFavPages,
    // VbCart,
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])

    return {
      user,
    }

  },
  data() {
    return [

      ]
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
