import router from '@/router/router'
import * as oauth from '@/api/endpoints/auth/oauth'
import { notification } from 'ant-design-vue'

const mapAuthProviders = {
  oauth: {
    login: oauth.login,
    register: oauth.register,
    currentAccount: oauth.currentAccount,
    logout: oauth.logout,
  },
}

/**
 * Maps the User Data coming back from JSON
 * to Data Store structure
 * @param user
 * @return { currentUser, fb, firebase }
 */
function mapUserDataToDataStore(user) {
  const fbProfile = user.facebook_profile
  const fbToken = user.facebook_access_token
  const firebaseAccessToken = user.firebase_access_token

  const currentUser = {
    // Current User info
    id: user.id,
    name: user.name,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    username: user.username,
    phone: user.phone,
    countryId: user.country_id,
    phoneE164: user.phone_e164,
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    profilePictureUrl: user.profile_picture_url,
    gender: user.gender,
    dob: user.date_of_birth,
    emailVerifiedAt: user.email_verified_at,
    phoneVerifiedAt: user.phone_verified_at,
    facebookUserId: user.facebook_user_id,
    firebaseUid: user.firebase_uid,
  }

  var fb
  if (fbProfile && fbToken) {
    fb = {
      userID: fbToken.facebook_user_id,
      appID: fbToken.facebook_app_id,
      accessToken: fbToken.access_token,
      expirationDate: fbToken.expiration_date,
      dataAccessExpirationDate: fbToken.data_access_expiration_date,
      refreshToken: fbToken.refresh_dae,
      firstName: fbProfile.first_name,
      lastName: fbProfile.last_name,
      email: fbProfile.email,
      dob: fbProfile.gender,
      profilePictureUrl: fbProfile.profile_pic_fb_url,
      gender: fbProfile.gender,
      hometown: fbProfile.hometown_name,
      location: fbProfile.location_name,
      permissionsRequested: fbToken.permissions_requested,
      permissionsGranted: fbToken.permissions_granted,
      permissionsDenied: fbToken.permission_denied,
      lastFBToAppSync: null,
      lastAppToBackEndSync: null,
    }
  }

  var firebase
  if (firebaseAccessToken) {
    firebase = {
      phoneE164: user.phone_e164,
      verificationID: firebaseAccessToken.verification_id,
      verificationCode: firebaseAccessToken.verification_code,
      verified: true,
      verifiedAt: firebaseAccessToken.verified_at,
      uid: firebaseAccessToken.uid,
      token: firebaseAccessToken.token,
      refreshToken: firebaseAccessToken.refresh_token,
    }
  }

  return {
    currentUser: currentUser,
    fb: fb,
    firebase: firebase,
  }
  // return { currentUser, fb, firebase }
}

function mapAuthDataToDataStore(auth) {
  // console.log('Auth coming back ' + auth.access_token)
  if (!auth.access_token) {
    console.error('Setting the access token to be blank')
  }
  const authorisation = {
    // authorisation info
    tokenType: auth.token_type,
    expiresIn: auth.expires_in,
    expiryDate: auth.expiry_date,
    accessToken: auth.access_token,
    refreshToken: auth.refresh_token,
  }
  return authorisation
}

export default {
  namespaced: true,
  state: {
    currentUser: {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      phone: '',
      countryId: '',
      phoneE164: '',
      createdAt: null,
      updatedAt: null,
      profilePictureUrl: '',
      dob: null,
      gender: '',
      hometown: '',
      location: '',
      emailVerifiedAt: null,
      phoneVerifiedAt: null,
      avatar: '', // TODO: to remove once figure out dont need this
    },
    // TODO : Need to organise the role issue and sync with the iOS Application as well
    role: '',
    loading: false,
    // authorized is the equivalent of isLoggedIn in xcode ios app
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    // authorized: true, // false is default value
    // main authorization
    authorisation: {
      tokenType: '',
      expiresIn: '',
      expiryDate: null,
      accessToken: '',
      refreshToken: '',
    },
    fb: {
      userID: '',
      appID: '',
      accessToken: '',
      expirationDate: null,
      dataAccessExpirationDate: null,
      refreshToken: null,
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      profilePictureUrl: '',
      gender: '',
      hometown: '',
      location: '',
      permissionsRequested: [],
      permissionsGranted: [],
      permissionsDenied: [],
      lastFBToAppSync: null,
      lastAppToBackEndSync: null,
    },
    firebase: {
      phoneE164: '',
      verificationID: '',
      verificationCode: '',
      verified: false,
      verifiedAt: null,
      uid: '',
      token: '',
      refreshToken: '',
    },
    verifyPhone: {
      phoneE164: '',
      phoneInternational: '',
      phoneNational: '',
      phoneRFC3966: '',
      phoneRaw: '',
      country2LetterCode: '',
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { username, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      // we want to remove the map authorisation provider here - not necessary
      const login = mapAuthProviders[rootState.settings.authProvider].login
      login(username, password).then(success => {
        // alert('are we here' + success)
        if (success) {
          // dispatch('LOAD_CURRENT_ACCOUNT')
          // Vue.prototype.$notification.success({
          //   message: 'Logged In',
          //   description: 'You have successfully logged in to Tieno!',
          // })

          // if it's successful, we have the data (not the error)
          // get the objects here to make referring them easier
          const data = success
          const { currentUser, fb, firebase } = mapUserDataToDataStore(data.user)
          const authorisation = mapAuthDataToDataStore(data.auth)
          // role
          const role = 'whatever' // TODO: Have to figure out roles and wallets here

          notification.success({
            message: 'Login succeeded',
            description: 'Welcome back to Tieno',
          })

          // const { id, email, name, avatar, role } = success.data.
          commit('SET_STATE', {
            currentUser,
            role,
            authorized: true,
            authorisation,
            fb,
            firebase,
          })
          commit('SET_STATE', {
            loading: false,
          })
        }
        if (!success) {
          notification.error({
            message: 'Login failed',
            description: 'Please check your username and password',
          })
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    REGISTER({ commit, dispatch, rootState }, { payload }) {
      const { email, password, name } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const register = mapAuthProviders[rootState.settings.authProvider].register
      register(email, password, name).then(success => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT')
          notification.success({
            message: 'Successfully Registered',
            description: 'You have successfully registered!',
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOAD_CURRENT_ACCOUNT({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })

      const currentAccount = mapAuthProviders[rootState.settings.authProvider].currentAccount
      currentAccount().then(response => {
        if (response) {
          // if it's successful, we have the data (not the error)
          // get the objects here to make referring them easier
          const data = response
          const { currentUser, fb, firebase } = mapUserDataToDataStore(data.user)
          // role
          const role = 'whatever' // TODO: Have to figure out roles and wallets here

          // const { id, email, name, avatar, role } = success.data.
          commit('SET_STATE', {
            currentUser,
            role,
            fb,
            firebase,
          })
          commit('SET_STATE', {
            loading: false,
          })
        }
        commit('SET_STATE', {
          loading: false,
        })
      })
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout
      logout().then(() => {
        // commit('CLEAR_ALL')
        commit('SET_STATE', {
          currentUser: {
            id: '',
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            username: '',
            phone: '',
            countryId: '',
            phoneE164: '',
            createdAt: null,
            updatedAt: null,
            profilePictureUrl: '',
            dob: null,
            gender: '',
            hometown: '',
            location: '',
            emailVerifiedAt: null,
            phoneVerifiedAt: null,
            avatar: '', // TODO: to remove once figure out dont need this
          },
          // TODO : Need to organise the role issue and sync with the iOS Application as well
          role: '',
          loading: false,
          // authorized is the equivalent of isLoggedIn in xcode ios app
          authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
          // authorized: true, // false is default value
          // main authorization
          authorisation: {
            tokenType: '',
            expiresIn: '',
            expiryDate: null,
            accessToken: '',
            refreshToken: '',
          },
          fb: {
            userID: '',
            appID: '',
            accessToken: '',
            expirationDate: null,
            dataAccessExpirationDate: null,
            refreshToken: null,
            firstName: '',
            lastName: '',
            email: '',
            dob: '',
            profilePictureUrl: '',
            gender: '',
            hometown: '',
            location: '',
            permissionsRequested: [],
            permissionsGranted: [],
            permissionsDenied: [],
            lastFBToAppSync: null,
            lastAppToBackEndSync: null,
          },
          firebase: {
            phoneE164: '',
            verificationID: '',
            verificationCode: '',
            verified: false,
            verifiedAt: null,
            uid: '',
            token: '',
            refreshToken: '',
          },
          verifyPhone: {
            phoneE164: '',
            phoneInternational: '',
            phoneNational: '',
            phoneRFC3966: '',
            phoneRaw: '',
            country2LetterCode: '',
          },
        })
        router.push('/auth/login')
      })
    },
  },
  getters: {
    user: state => state.currentUser,
    authorized: state => state.authorized,
    currentUser: state => state.currentUser,
    loading: state => state.loading,
  },
}
