import * as itemTypesApiClient from '@/api/endpoints/item/item-types'

export default {
  namespaced: true,
  state: {
    itemTypes: [],
  },
  mutations: {
    SET_ITEM_TYPES(state, itemTypes) {
      state.itemTypes = itemTypes
    },
  },
  actions: {
    fetchItemTypes: ({ commit }) => {
      itemTypesApiClient.fetchList().then(function (response) {
        console.log('fetchItemTypes returned')
        console.log(response)
        if (response.status === 'success') {
          commit('SET_ITEM_TYPES', response.data)
        }
      })
    },
  },
  getters: {},
}
