import { createStore } from 'vuex'

// Data Modules
import user from './modules/user'
import settings from './modules/settings'
import common from './modules/common'
import entity from './modules/entity'
import item from './modules/item'
import consumer from './modules/consumer'

import createPersistedState from 'vuex-persistedstate' // great to persist state in the browser itself
// import createMutationsSharer from 'vuex-shared-mutations' // shared state between tabs - TODO: Check Implications
// development debugging
// not sure the difference between these two
import { createLogger } from 'vuex'
// import createCustomLogger from './plugins/logger'

// are we in debug mode
const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    user,
    settings,
    common,
    entity,
    item,
    consumer,
  },
  strict: debug,
  plugins: debug ? [createLogger(), createPersistedState()] : [createPersistedState()],
  state: {},
  mutations: {
    /**
     * Clear all state
     * @event clearAll
     * @type {Function}
     * @public
     */
    CLEAR_ALL: state => {
      Object.keys(state).forEach(key => {
        state[key] = []
      })
    },
  },
  actions: {
    /**
     * commit mutations & fire clearAll function
     * @event clearAll
     * @type {Function}
     * @public
     * @param {String}
     */
    logout: ({ commit }) => {
      console.warning('Clearing all local data store objects')
      commit('CLEAR_ALL')
    },
  },
  getters: {
    accessToken: (state, getters) => {
      return state.user.authorisation.accessToken
    },
  },
})
