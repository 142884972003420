/**
 * Check if we want to be debugging
 * @return {boolean}
 */
export function isInDebugMode () {
  const wantsToDisplayMessages = false
  if (window.webpackHotUpdate && process.env.VUE_APP_DEBUG_MODE && wantsToDisplayMessages) {
    return true
  }
  return false
}
