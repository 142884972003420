import request from '@/api/utils/axios'

export function fetchMyAccountSummary(query) {
  return request({
    url: '/consumer/my/moneyaccount/summary',
    method: 'get',
  })
}

export function storePocket(data) {
  return request({
    url: '/consumer/my/pockets/',
    method: 'post',
    data,
  })
}
